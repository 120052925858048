import Web3 from 'web3';
import { getBlockchainData } from './metamask-connection.js';
import { getContractNft, nftAddress, os, web3link } from './smart-contracts-config.js';

const gasFactor = 1;

export const claimRewards = async () => {
  const setLoading = () => {}
  const setShowMessage = () => { }
  
  getBlockchainData(async (account, web3) => {
    const contract = getContractNft(web3);

    const depositsOf = await contract.methods.depositsOf(account).call();
    
    const method = contract.methods.claimRewards(depositsOf);
    let options = {
      from: account,
      gas: '0',
    };
    try {
      const estimateGasPrice1 = await method.estimateGas(options);
      const estimateGasPrice2 = Math.trunc(gasFactor * estimateGasPrice1);
      options = { ...options, gas: '' + estimateGasPrice2 };
    } catch (e) {
      let msg;
      try {
        console.log(e.message);
        let a = e.message;
        let objStr = a.substring(a.indexOf('{'), a.lastIndexOf('}') + 1);
        // console.log({ objStr });
        msg = JSON.parse(objStr).message || JSON.parse(objStr).originalError.message;
        msg = msg.replace('err: ', '');
        msg = msg.replace('execution reverted: ', '');
      } catch (eiii) {}

      if (!msg || msg === undefined) {
        msg = 'Insufficient funds or Some other error ' + e;
      }
      if (msg === 'MSG TO REPLACE') msg = 'YOUR MSG';
      else if (msg === 'MSG TO REPLACE') msg = 'YOUR MSG';
      else if (msg === 'MSG TO REPLACE') msg = 'YOUR MSG';
      else if (msg === 'MSG TO REPLACE') msg = 'YOUR MSG';
      // else if (msg === 'Sale is not active') msg = 'Sale will start at 10pm UTC';

      alert(msg);
      return;
    }

    try {
      await method
        .send(options)
        .on('transactionHash', (tx) => setLoading(true))
        .on('confirmation', (i, tx) => {
          console.log({ i, i2: i === 1, tx });
          if (i === 1) {
            setLoading(false);
          }
        });
    } catch (e) {
      setLoading(false);
      if (e.message === 'MetaMask Tx Signature: User denied transaction signature.') {
        // setShowMessage('User denied transaction');
      } else setShowMessage(e.message);
    }
  });
};

export const purchaseDigiCollect = async (setLoading, howMany, setShowMessage, setWallet, setShowNftMintMessage, referrer, setBalance) => {
  if (!howMany || isNaN(howMany) || howMany === 0) {
    setShowMessage('Enter some quantity to Mint');
    return;
  }
  getBlockchainData(async (account, web3) => {
    setWallet(shortAddress(account));

    const contract = getContractNft(web3);

    const price = await contract.methods.getPrice(howMany).call();
    console.log({ price });
    
    const method = contract.methods.buyDigiCollect(howMany, referrer);
    let options = {
      from: account,
      gas: '0',
      value: price,
    };
    try {
      const estimateGasPrice1 = await method.estimateGas(options);
      const estimateGasPrice2 = Math.trunc(gasFactor * estimateGasPrice1);
      options = { ...options, gas: '' + estimateGasPrice2 };
    } catch (e) {
      let msg;
      try {
        console.log(e.message);
        let a = e.message;
        let objStr = a.substring(a.indexOf('{'), a.lastIndexOf('}') + 1);
        // console.log({ objStr });
        msg = JSON.parse(objStr).message || JSON.parse(objStr).originalError.message;
        msg = msg.replace('err: ', '');
        msg = msg.replace('execution reverted: ', '');
      } catch (eiii) {}

      if (!msg || msg === undefined) {
        msg = 'Insufficient funds';
      }
      if (msg === 'MSG TO REPLACE') msg = 'YOUR MSG';
      else if (msg === 'MSG TO REPLACE') msg = 'YOUR MSG';
      else if (msg === 'MSG TO REPLACE') msg = 'YOUR MSG';
      else if (msg === 'MSG TO REPLACE') msg = 'YOUR MSG';
      // else if (msg === 'Sale is not active') msg = 'Sale will start at 10pm UTC';

      setShowMessage(msg);
      alert(msg);
      return;
    }

    try {
      await method
        .send(options)
        .on('transactionHash', (tx) => setLoading(true))
        .on('confirmation', (i, tx) => {
          console.log({ i, i2: i === 1, tx });
          if (i === 1) {
            setBalance(1);// just to show ref link
            setLoading(false);
            // let tokenId = Number(tx.events['0'].raw.topics[3]);
            // setShowNftMintMessage(`${os}/${nftAddress}/${tokenId}`);
            if (window.confirm('See your Digi Collect now on on OpenSea. Refresh the page if Digi Collect does not show.'))
              window.location.href = `https://opensea.io/collection/digi-collect-labs`;
          }
        });
    } catch (e) {
      setLoading(false);
      if (e.message === 'MetaMask Tx Signature: User denied transaction signature.') {
        // setShowMessage('User denied transaction');
      } else setShowMessage(e.message);
    }
  });
};

export const connectWallet = async (setWallet) => {
  getBlockchainData(async (account, web3) => {
    setWallet(account);
  });
};

export const getPrice = async (setPrice) => {
  const web3 = new Web3(web3link);
  const contract = getContractNft(web3).methods;
  const priceWei = await contract.getPrice(1).call();
  const priceEth = web3.utils.fromWei(priceWei);
  setPrice(`${priceEth}`);
};

export const getPriceInfo = async (setPrice) => {
  const web3 = new Web3(web3link);
  const contract = getContractNft(web3).methods;
  const priceWei = await contract.getPrice(1).call();
  const priceEth = 0.005 + Number(web3.utils.fromWei(priceWei));

  const totalSupply = await contract.totalSupply().call();
  console.log("totalSupply " + totalSupply);
  const block = 200;
  const priceIncreaseInNext = block - totalSupply % block;

  setPrice(`${priceIncreaseInNext} NFTs left at this price, Next Price is: ${priceEth}ETH`);
  // setPrice(`${priceIncreaseInNext} NFTs left at this price, Next Price is: ${priceEth}ETH<br/>${totalSupply} NFTs are sold out!`);
};

export const getBalance = async (setBalance) => {
  getBlockchainData(async (account, web3) => {
    const balanceOf = await getContractNft(web3).methods.balanceOf(account).call();
    setBalance(balanceOf);
  });
};

export const getDeposits = async (setDeposits) => {
  getBlockchainData(async (account, web3) => {
    const depositsOf = await getContractNft(web3).methods.depositsOf(account).call();
    setDeposits(depositsOf);
  });
};

export const getTokenIds = async (setTokenIds) => {
  let diff = (arr1, arr2) => arr1.filter(x => !arr2.includes(x));

  getBlockchainData(async (account, web3) => {
    const tokensOfOwner = await getContractNft(web3).methods.tokensOfOwner(account).call();
    const depositsOf = await getContractNft(web3).methods.depositsOf(account).call();
    setTokenIds(diff(tokensOfOwner, depositsOf));
  });
};

export const getReward = async (setReward) => {
  getBlockchainData(async (account, web3) => {
    const contract = getContractNft(web3);
    const tokensOfOwner = await contract.methods.tokensOfOwner(account).call();
    const rewardOfListWei = await getContractNft(web3).methods.calculateRewards(account, tokensOfOwner).call();
    const rewardOfListEth = rewardOfListWei.map(a => Number(web3.utils.fromWei(a))) 
    const rewardEth = rewardOfListEth.reduce((a, b) => a + b, 0)
    setReward(rewardEth);
  });
};

export const withdraw = async () => {
  const setLoading = () => {};
  const setShowMessage = () => {};

  getBlockchainData(async (from, web3) => {
    const contract = getContractNft(web3);
    const depositsOf = await contract.methods.depositsOf(from).call();
    console.log({depositsOf});
    const method = contract.methods.withdraw(depositsOf);
    let options = {from};

    try {
      const gas = '' + Math.trunc(gasFactor * (await method.estimateGas(options)));
      options = { from, gas };
    } catch (e) {
      let msg;
      try {
        let a = e.message;
        let objStr = a.substring(a.indexOf('{'), a.lastIndexOf('}') + 1);
        msg =
          JSON.parse(objStr).message ||
          JSON.parse(objStr).originalError.message;
        msg = msg.replace('err: ', '');
        msg = msg.replace('execution reverted: ', '');
      } catch (err) {}
      if (!msg || msg === undefined) msg = 'Insufficient funds';
      if (msg === 'MSG TO REPLACE') msg = 'YOUR MSG';
      else if (msg === 'MSG TO REPLACE') msg = 'YOUR MSG';
      alert(msg);
      return;
    }

    try {
      await method
        .send(options)
        .on('transactionHash', (tx) => setLoading(true))
        .on('confirmation', (i, tx) => {
          if (i === 1)
            if (window.confirm('See your $DIGI now on Etherscan'))
              window.location.href = `https://etherscan.io/address/${from}`;
        });
    } catch (e) {
      setLoading(false);
      if (
        e.message ===
        'MetaMask Tx Signature: User denied transaction signature.'
      ) {
      } else setShowMessage(e.message);
    }
  });
};

export const deposit = async (tokenIds) => {
  console.log({ tokenIds });
  const setLoading = () => {};
  const setShowMessage = () => {};

  getBlockchainData(async (from, web3) => {
    console.log(from);
    const contract = getContractNft(web3);
    const method = contract.methods.deposit(tokenIds);
    let options = {from};
    try {
      const gas = '' + Math.trunc(gasFactor * (await method.estimateGas(options)));
      options = { from, gas };
    } catch (e) {
      let msg;
      try {
        let a = e.message;
        let objStr = a.substring(a.indexOf('{'), a.lastIndexOf('}') + 1);
        msg =
          JSON.parse(objStr).message ||
          JSON.parse(objStr).originalError.message;
        msg = msg.replace('err: ', '');
        msg = msg.replace('execution reverted: ', '');
      } catch (err) {}
      if (!msg || msg === undefined) msg = 'Insufficient funds';
      if (msg === 'MSG TO REPLACE') msg = 'YOUR MSG';
      else if (msg === 'MSG TO REPLACE') msg = 'YOUR MSG';
      alert(msg);
      return;
    }

    try {
      await method
        .send(options)
        .on('transactionHash', (tx) => setLoading(true))
        .on('confirmation', (i, tx) => {
          if (i === 1)
            if (window.confirm('See your $DIGI now on Etherscan'))
              window.location.href = `https://etherscan.io/address/${from}`;
        });
    } catch (e) {
      setLoading(false);
      if (
        e.message ===
        'MetaMask Tx Signature: User denied transaction signature.'
      ) {
      } else setShowMessage(e.message);
    }
  });
};

export const shortAddress = (_address) => {
  return _address && _address.substr(0, 5) + '****' + _address.substr(_address.length - 4, _address.length);
};
