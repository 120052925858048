import React, { useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import { Link } from 'react-router-dom';
import Accordion from 'react-bootstrap/Accordion'

// import Slider from 'react-slick';

// import LazyLoad from 'react-lazyload';

const Slider = React.lazy(() => import('react-slick'));

//import * as THREE from 'three'
//import {gsap, ScrollTrigger} from 'gsap'
//import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
//import { DRACOLoader } from "three/examples/jsm/loaders/DRACOLoader";

function detectMob() {
  if (window.innerWidth <= 500 && window.innerHeight <= 920) return 'mobile';
  if (window.innerWidth <= 1024 && window.innerHeight <= 1244) return 'tablet';
  if (window.innerWidth <= 1440 && window.innerHeight <= 1244)
    return 'laptop_small';
  else return 'laptop_large';
}

const isMobile = detectMob();
const imgFolder = isMobile === 'mobile' ? 'images-mobile' : 'images';

const hi = () => {
  console.log('aoa');
  return true;
};

const observer = new IntersectionObserver(entries => {
  entries.forEach(entry => {
    if (entry.isIntersecting) {
      entry.target.classList.add('show');
    } else {
      entry.target.classList.remove('show');
    }
  });
});

const Home = () => {
  useEffect(() => {
    console.log({ isMobile: isMobile === 'mobile' });

    hi();
    document.querySelectorAll('.hidden').forEach(el => observer.observe(el));
  }, []);
  
  const handleButtonClick = () => {
    // Scroll to the top of the page when the button is clicked
    window.scrollTo(0, 0);
  };

  var settings = {
    dots: false,
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
  };
  return (
    <>
  {!(isMobile === 'mobile') ? (
     <div className='header'style={{display:'flex',marginTop:'20px'}}>
     <Link to="/"> <img className='hidden hidden1' src='images/digi-logo.webp' alt='Digicollect' style={{ height: '55px' }} /></Link>
          
          <div style={{ display:"flex",position:"absolute", right:"5%"}}>
           <nav className="Navbar"  style={{display:'flex',justifyContent:'center'}}>
         
         <ul style={{display:'flex',justifyContent:'center',marginTop:'13px'}}>
           <li>
             <h2><a style={{color:'gold',textDecoration:'none',padding:'10px'}} href="#Benefits">Benefits</a></h2>
           </li>
           <li>
           <h2><a style={{color:'gold',textDecoration:'none',padding:'10px'}} href="#Digibrand">Digi Brand</a></h2>
           </li>
           <li>
           <h2><a style={{color:'gold',textDecoration:'none',padding:'10px'}} href="#Roadmap">Roadmap</a></h2>
           </li>
           <li>
           <h2><a style={{color:'gold',textDecoration:'none',padding:'10px'}} href="/mint">MINT</a></h2>
           </li>
         </ul> 
            <a href='https://twitter.com/DigicollectLabs' style={{paddingRight:"10px"}}  target={'_blank'}>
              <img src='images/twitter-icon.png' alt='icon' />
            </a>
            <a href='https://youtube.com/@digicollectlabs2329'  target={'_blank'}>
              <img src='images/youtube-icon.png' alt='icon' />
            </a>
            <a href='https://youtube.com/@digicollectlabs2329'  target={'_blank'}>
              <img src='images/discord-icon.png' alt='icon' />
            </a> 
            <a href='https://youtube.com/@digicollectlabs2329'  target={'_blank'}>
              <img src='images/medium-icon.png' alt='icon' />
            </a></nav>
      </div>
      </div>
  ):(  
           <nav className="Navbar"  style={{display:'flex',justifyContent:'center'}}>
         
         <ul style={{display:'flex',justifyContent:'center',marginTop:'13px'}}>
           <li>
             <h2><a style={{color:'gold',textDecoration:'none',padding:'10px',fontSize:'16px'}} href="#Benefits">Benefits</a></h2>
           </li>
           <li>
           <h2><a style={{color:'gold',textDecoration:'none',padding:'10px',fontSize:'16px'}} href="#Digibrand">Digi Brand</a></h2>
           </li>
           <li>
           <h2><a style={{color:'gold',textDecoration:'none',padding:'10px',fontSize:'16px'}} href="#Roadmap">Roadmap</a></h2>
           </li>
           <li>
           <h2><a style={{color:'gold',textDecoration:'none',padding:'10px',fontSize:'16px'}} href="/mint">MINT</a></h2>
           </li>
         </ul> 
            </nav>
  )}
      <section className='banner-section'>
        <div className='banner-container' style={{backgroundImage: "url('./images/banner.jpeg')"}} >
    
          {/* <div className='head-icon'>
            <img className='hidden' src='images/logo-icon.webp' alt='icon' />
          </div> */}
          {/* <div className='banner-cartoon-img'><img src="images/carton-img1.webp" alt="Digicollect" /></div> */}
          <div className='banner-content'>
          {!(isMobile === 'mobile') ? (
            <div className='banner-logo hidden'>
              {/* <span>
                <img src='images/digi-logo.webp' alt='logo' />
              </span> */}
          </div>):( <div className='banner-logo hidden'>
              <span>
                <img src='images/digi-logo.webp' alt='logo' />
              </span>
          </div>)}
            <div className='content-detail'>
              <div className='banner-title hidden hidden1'>
                <img src='images/banner-text-bg.webp' alt='Digicollect' />
                <h1> Digi Travel Club </h1>
              </div>
              <div className='content-discriptions hidden hidden1'>
                <p>
                Welcome to Digi Travel Club, the ultimate platform for travel-inspired NFTs and Web3 digital art nomads 
                </p>
                <p>Join our global Web3 network of travel enthusiasts and skilled artists as we celebrate various cultures and destinations through distinctive NFT collections </p>
                <p>Join our journey uniting adventurers and creators, and join the digital club of Web3 travel world!</p>
              </div>
            </div>
            </div>
        
          {/* <div className='banner-enter-text'>
            {!(isMobile === 'mobile') ? (
              <div className='text-hand-image hidden hidden1'>
                <img src='images/hand-img.webp' alt='Digicollect' style={{ width: '400px' }} />
              </div>
            ) : (
              <div className='text-hand-image hidden hidden1'>
                <img src='images/hand-img.webp' alt='Digicollect' style={{ width: '100px' }} />
              </div>)}
          </div> */}
          {/* <div className='' style={{position:'absolute',top:'200px',width:'20%'}}
          >
            {!(isMobile === 'mobile') ? (
              <div className='dimond-video hidden'>
              <h1> Digicollect edition 1: Travel Panda</h1>
                    <img src='images/panda-gif.gif' alt='Digicollect' />
                    </div>
            ) : (
              <div className='dimond-video hidden'>
              <h1> Digicollect edition 1: Travel Panda</h1>
                    <img src='images/panda-gif.gif' alt='Digicollect' />
                    </div>)}
          </div> */}
          {!(isMobile === 'mobile') ? (
          <div className="d-flex justify-content-center" style={{ paddingTop: '50px' }}>
            <Link to="/mint">
              <Button variant="outline-warning" className="custom-btn-size">
                MINT
              </Button>
            </Link>
          </div>
          ) : (<>
            {/* <div className='dimond-video hidden'>
              <h1> Digicollect edition 1: Travel Panda</h1>
                    <img src='images/panda-gif.gif' alt='Digicollect' />
                    </div> */}
                
        <div style={{ display:"flex",paddingBottom:'10px',padding:'inherit', justifyContent :'center',marginTop:'10%'}}>
            <a href='https://twitter.com/DigicollectLabs' target={'_blank'}>
              <img src='images/twitter-icon.png' alt='icon' style={{maxWidth:'70%'}} />
            </a>
            <a href='https://youtube.com/@digicollectlabs2329'  target={'_blank'}>
              <img src='images/youtube-icon.png' alt='icon' style={{maxWidth:'70%'}}  />
            </a>
            <a href='https://youtube.com/@digicollectlabs2329'  target={'_blank'}>
              <img src='images/discord-icon.png' alt='icon' style={{maxWidth:'70%'}}  />
            </a>
            <a href='https://youtube.com/@digicollectlabs2329'  target={'_blank'}>
              <img src='images/medium-icon.png' alt='icon' style={{maxWidth:'70%'}}  />
            </a>
      </div>  <div className="d-flex justify-content-center" style={{ paddingTop: '40px' }}>
            <Link to="/mint">
              <Button variant="outline-warning" className="custom-btn-size3" onClick={handleButtonClick}>
              MINT
              </Button>
            </Link>
            
          </div>  </>)}
        </div>
      </section>
      
      {/* <div style={{ display: 'flex', marginTop: '120px',background: `url('images/Panda-1.gif')`,
    backgroundSize: '100% 100%',
    position: 'relative',
    backgroundRepeat: 'no-repeat'}}>
        <div>
          <img src='images/page2.png' alt='Digicollect' style={{ width: '80%', marginLeft: 'auto', marginRight: 'auto', opacity: '0' }} />
        </div>
        <div>
          <img src='images/page2.png' alt='Digicollect' style={{ width: '80%', marginLeft: 'auto', marginRight: 'auto' }} />
        </div>
      </div> */}
    <div style={{ display: 'flex', marginTop: '50px',marginLeft:'20px',justifyContent :'center' }} id='Benefits'>
        <div style={{ flex: 1 }}>
          <h1 style={{ marginBottom: '40px' }}>Explore Our Platform: Exclusive NFT Drops, Networking & Community Building</h1>
          <h2>No Whitelists & Dynamic Pricing:</h2>
          <p>Skip restrictive whitelists with our fair NFT platform, rewarding early adopters. Our 10,000 unique NFTs see prices increase by 0.005 ETH every 200 sales.</p>
          <h2>Web3 Networking & Rewards:</h2>
          <p>Connect with travel enthusiasts on our platform. As an NFT holder, enjoy 20% mint referral rewards and exclusive Web3 networking opportunities.</p>
          <h2>Join Our Global Family:</h2>
          <p>Be part of our diverse community, celebrating global cultures and destinations. We champion unity and inclusivity, inviting you to our worldwide family.</p>
        </div>
        
        <div style={{ flex: 1 }}>
        <div style={{ float: 'right' }}>
        <img src="images/panda-gif.gif" alt="Image" style={{ width: '80%' }} />
      </div>
        </div>
        </div>
      <Link to="/mint">
              <Button variant="outline-warning" className="custom-btn-size3" style={{marginLeft:'20px'}} onClick={handleButtonClick}>
                MINT
               </Button>
               </Link>
      <section className='morph-collection'>
        <div className='collection-container'id='Digibrand'>
          <div className='morph-collection-bgimg'>
            {/* <img src='images/carton-img3.webp' alt='images' /> */}

            <video autoPlay loop muted inline='true' width='100%' style={{ marginTop: '35px' }}>
              <source src='images-mint/PAnda world tour.mp4' type='video/mp4' />
            </video>
            {/* <video autoPlay loop muted  inline='true' width='100%' style={{ marginTop: '35px' }}>
              <source src='images-mint/panda.mp4' type='video/mp4' />
            </video> */}
            {/* <div style={{ maxWidth: '1080px', maxHeight: '1089px' }}></div> */}
          </div>
          <div className='morph-collection-detail'>
            {isMobile === 'mobile' ? (
              <img src='images/ticket-img.png' alt='Digicollect' style={{ scale: '0.8', marginLeft: '30px' }} />
            ) : (
              <>
                <div className='morph-collection-card hidden hidden1'>
                  <img src='images/ticket-img.png' alt='Digicollect' style={{ scale: '0.8', marginLeft: 'auto' }} />
                </div>

              </>
            )}
            <div className='morpht-collection-circle hidden hidden1'>
              <img src='images/circle-img.webp' alt='Digicollect' />
            </div>
          </div>
        </div>
      </section>
      <section className='dimond-section' id='Roadmap'>
        <div className='container'>
          {/* <LazyLoad height={800}> */}
          <Row style={{ marginBottom: '60px' }}>
            <Col lg={6} md={6}>
              <div className='dimond-video hidden'>
                <img src='images/Gif.gif' alt='Digicollect' />
                <div className='edouard-title hidden hidden1'>
                  {/* <img src='images/roadmap.webp' alt='Digicollect' /> */}
                </div>
                <div className='dimond-video-title'>
                  {/* <img src='images/text-4.webp' alt='Digicollect' /> */}
                </div>
              </div>
            </Col>
            <Col lg={6} md={6} style={{ paddingTop: '50px' }}>
              <div className='edouard-section'>
                <div className='edouard-content-slider hidden hidden1'>
                  <div className='edouard-slider'>
                    {/* <LazyLoad height={400}> */}
                    <Slider {...settings}>
                      <div className='slider-image'>
                        <img src='images/slider-img1.webp' alt='celebrity' />
                      </div>
                      <div className='slider-image'>
                        <img src='images/slider-img3.jpeg' alt='celebrity' />
                      </div>
                      <div className='slider-image'>
                        <img src='images/slider-img2.webp' alt='Digicollect' />
                      </div>
                    </Slider>
                    {/* </LazyLoad> */}
                  </div>
                  <div className='edouard-content' style={{ marginTop: '3px' }}>
                    <div className='edouard-content-dis'>
                      <p>
                        <b>Roadmap</b> <br />
                         Transforming NFT landscape through community-driven Web3 culture creation
                      </p>
                      <p>
                        {/* <b>Tokenomics</b> <br /> */}
                        Launching first NFT edition; subsequent editions shaped by community input, steering clear of cash grab tactics
                      </p>
                      <p>
                      Collaborate in our journey to redefine NFT future with creativity, fun, and shared decision-making
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          {/* </LazyLoad> */}

          <div className='collab-section'>
            {/* <div className='collab-image hidden hidden1'>
              <img src='images/img1.webp' alt='Digicollect' />
            </div> */}
            {/* <div className='collab-title hidden hidden1'>
              <img src='images/collab-img.webp' alt='collab' />
            </div> */}
          </div>
        </div>
      </section>
      <section className='toundery-section'>
        <div className='container'>
          <div className='toundery-title hidden hidden1'>
            <img src='images/digi-logo.webp' alt='logo' />
            <div className='toundery-heading'>
              <img src='images/text-5.webp' alt='Digicollect' />
            </div>
            <div className='toundery-content'>
              <p>
                Nothing would be possible without a great team, here are some
                members of our board:
              </p>
            </div>
          </div>
          <div className='toundery-list'>
            <ul>
              <li className='hidden hidden1'>
                <div className='list-detail'>
                  <a href='https://twitter.com/DigicollectLabs' className='list-img'>
                    <img src='images/toy-img1.webp' alt='Digicollect' />
                  </a>
                  <div className='list-content'>
                    <p>
                      {/* <br /> */}
                      <br />
                      <br />
                      He is the founder and the mastermind of the team,
                      <br />
                      <br />
                      He is Degeta.
                    </p>
                  </div>
                </div>
              </li>
              <li className='hidden hidden1'>
                <div className='list-detail'>
                  <a href='https://twitter.com/DigicollectLabs' className='list-img'>
                    <img src='images/toy-img2.webp' alt='Digicollect' />
                  </a>
                  <div className='list-content'>
                    <p>
                      <br />
                      <br />
                      After having already created several successful companies in the digital space, passionate about Web3, he launches into the creation of
                      digi collect.
                    </p>
                  </div>
                </div>
              </li>
              <li className='hidden hidden1'>
                <div className='list-detail'>
                  <a href='https://twitter.com/DigicollectLabs' className='list-img'>
                    <img src='images/toy-img3.webp' alt='Digicollect' />
                  </a>
                  <div className='list-content'>
                    <p>
                      <br />
                      <br />
                      she is the chilled-out marketing goddess, in her Golden
                      mode nobody can touch her in marketing strategies
                    </p>
                  </div>
                </div>
              </li>

              <li className='hidden hidden1'>
                <div className='list-detail'>
                  <a href='https://twitter.com/DigicollectLabs' className='list-img'>
                    <img src='images/carton-img4.png' alt='Digicollect' />
                  </a>
                  <div className='list-content'>
                    <p>
                      <br />
                      <br />
                      GOTWEETA: King of community managing, you will feel his
                      intensity in the twitter and discord chats
                    </p>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div className='faq-section'>
          {/* <div className='faq-cartoon-img-one hidden hidden1'>
            <img src={imgFolder + '/carton-img44.png'} alt='Digicollect' />
          </div>
          <div className='container hidden hidden1'>
            <div className='faq-title'>
              <img src='images/text-6.webp' alt='images' />
            </div>
            <div className='faq-content'>
              <Accordion flush>
                <div className='faq-content-detail'>
                  <Accordion.Item className='faq-content-detail' eventKey='0'>
                    <h3>
                      <Accordion.Header>
                        <span>What is a Digicollect Genesis ?</span>
                      </Accordion.Header>
                    </h3>

                    <p>
                      <Accordion.Body>
                        Bridging the gap between the virtual and the physical world, morph is a community-driven Web3 brand centered around collaborations. A
                        morph Genesis will allow you to be part of the project (for life), to be able to claim free exclusive digital assets built by famous
                        artistic directors (that you can burn to obtain physical ones) and… much more that we can’t tell you yet ! #DeliverIsBetterThanPromise
                        Only 1000 Genesis morphs exist ! Small supply = exclusive Community.
                      </Accordion.Body>
                    </p>
                  </Accordion.Item>
                </div>

                <div className='faq-content-detail'>
                  <Accordion.Item className='faq-content-detail' eventKey='1'>
                    <h3>
                      <Accordion.Header>
                        <span>What is the Digicollect VISION ?</span>
                      </Accordion.Header>
                    </h3>

                    <p>
                      <Accordion.Body>
                        Our Vision is to gather all the most influential and
                        enthusiasts Web3 people from Fashion, Creativity, Art
                        and Technology. To collaborate with the best worldwide
                        artistic directors (we already started) and create very
                        limited editions.
                      </Accordion.Body>
                    </p>
                  </Accordion.Item>
                </div>

                <div className='faq-content-detail'>
                  <Accordion.Item className='faq-content-detail' eventKey='2'>
                    <h3>
                      <Accordion.Header>
                        <span>Is the team Doxxed ?</span>
                      </Accordion.Header>
                    </h3>

                    <p>
                      <Accordion.Body>
                        Yes we are ! Neither hiding behind a bear-market, nor turning our phones into airplane mode, we are here to last. You can find the main
                        actors of the morph project in the section above this F.A.Q. We also have a team of 20 people working hard on a daily basis to make this
                        project reach its Vision (you’ll meet them soon on our Discord).
                      </Accordion.Body>
                    </p>
                  </Accordion.Item>
                </div>

                <div className='faq-content-detail'>
                  <Accordion.Item className='faq-content-detail' eventKey='3'>
                    <h3>
                      <Accordion.Header>
                        <span>How often will there be collaborations ?</span>
                      </Accordion.Header>
                    </h3>

                    <p>
                      <Accordion.Body>
                        Multiple times a year, we will announce new morph collabs ! So you mean that by owning a morph Genesis, I'll be able to claim, several
                        times a year, exclusive free digital assets made by worldwide famous artistic directors and that I’ll be able to convert them into
                        physical ones ? YEEESIIIIIRR
                      </Accordion.Body>
                    </p>
                  </Accordion.Item>
                </div>

                <div className='faq-content-detail'>
                  <Accordion.Item className='faq-content-detail' eventKey='4'>
                    <h3>
                      <Accordion.Header>
                        <span>What is the first Digicollect collaboration ?</span>
                      </Accordion.Header>
                    </h3>

                    <p>
                      <Accordion.Body>
                        “DIAMOND-NECK” is the first morph collaboration. Created by the famous French jeweler Edouard Nahum (who already made creations for
                        P-Diddy, Madonna, Zidane…), this piece is a real tribute to the NFT universe. You can display any of your NFT (morph Genesis, Bored Ape,
                        Moonbird, Clone X, Azuki…) into it ! All the morph Genesis holders will be able to claim for free the digital asset of the necklace
                        (that you will then be able to burn to get the physical one… more information soon ⏳ ) PS : only real OG will wear that.
                      </Accordion.Body>
                    </p>
                  </Accordion.Item>
                </div>

                <div className='faq-content-detail'>
                  <Accordion.Item className='faq-content-detail' eventKey='5'>
                    <h3>
                      <Accordion.Header>
                        <span>
                          Why did you choose to launch your project in a
                          Bear-Market?
                        </span>
                      </Accordion.Header>
                    </h3>

                    <p>
                      <Accordion.Body>
                        We believe that the best period to build an authentic project is on a Bear Build-Market. Why ? Because then you only reach real
                        enthusiasts who feel connected to the project and will get involved to build strong foundations while staying focused on the true
                        utility and not on the Hype. *And also because morph’s founders have big c*cks (as our friend Vitalik)
                      </Accordion.Body>
                    </p>
                  </Accordion.Item>
                </div>

                <div className='faq-content-detail'>
                  <Accordion.Item className='faq-content-detail' eventKey='6'>
                    <h3>
                      <Accordion.Header>
                        <span>How are the 1000 morphs’ Genesis distributed ?</span>
                      </Accordion.Header>
                    </h3>

                    <p>
                      <Accordion.Body>
                        500 morphs NFT will be on private freemint by whales, influencers, and passionates about the project (all reserved), the other 500 will
                        be on a private sale and will give you access to everything (maximum = 2 per wallet).
                      </Accordion.Body>
                    </p>
                  </Accordion.Item>
                </div>

                <div className='faq-content-detail'>
                  <Accordion.Item className='faq-content-detail' eventKey='7'>
                    <h3>
                      <Accordion.Header>
                        <span>Which blockchain did you use ?</span>
                      </Accordion.Header>
                    </h3>

                    <p>
                      <Accordion.Body>
                        Is that real a question lmfao ? ETH ofc !
                      </Accordion.Body>
                    </p>
                  </Accordion.Item>
                </div>

                <div className='faq-content-detail'>
                  <Accordion.Item className='faq-content-detail' eventKey='8'>
                    <h3>
                      <Accordion.Header>
                        <span>Will the gas fees be expensive ?</span>
                      </Accordion.Header>
                    </h3>

                    <p>
                      <Accordion.Body>
                        We invested a ton of money these past months in R&D so
                        we can have the smoothest mint possible ! Our team of
                        engineers worked very hard to offer you the best
                        experience with the lowest gas fees.
                      </Accordion.Body>
                    </p>
                  </Accordion.Item>
                </div>

                <div className='faq-content-detail'>
                  <Accordion.Item className='faq-content-detail' eventKey='9'>
                    <h3>
                      <Accordion.Header>
                        <span>What are the funds being used for ?</span>
                      </Accordion.Header>
                    </h3>

                    <p>
                      <Accordion.Body>
                        Transparency is very important for us. The funds will be
                        used for 4 things :
                        <br />
                        • Collabs / Roadmap
                        <br />
                        • R&D
                        <br />
                        • To pay the team
                        <br />• For a security fund locked on a visible public
                        ledger
                      </Accordion.Body>
                    </p>
                  </Accordion.Item>
                </div>

                <div className='faq-content-detail'>
                  <Accordion.Item className='faq-content-detail' eventKey='10'>
                    <h3>
                      <Accordion.Header>
                        <span>Is investing in a morph Genesis a great investment, and if yes, why ?</span>
                      </Accordion.Header>
                    </h3>

                    <p>
                      <Accordion.Body>
                        By investing in this collection, you’ll obtain free limited edition digital assets made by artistic directors (specially for morph),
                        selling their art for tens of thousands of dollars (and millions for some…) so it will for sure have a value on the market. Also, by
                        investing on a solid and concrete NFT project when ETH is near the bottom, will not only make you benefit from the morph utility but
                        also from the morphs’ price growth + the ETH growth !
                      </Accordion.Body>
                    </p>
                  </Accordion.Item>
                </div>

                <div className='faq-content-detail'>
                  <Accordion.Item className='faq-content-detail' eventKey='11'>
                    <h3>
                      <Accordion.Header>
                        <span>Who is wanted into the Digicollect community ?</span>
                      </Accordion.Header>
                    </h3>

                    <p>
                      <Accordion.Body>
                        Even if we know that our project can be a great
                        financial asset for you, we don’t want people who will
                        only be there for the financial purpose. We are looking
                        for real NFT enthusiasts who will be part of the journey
                        with us. People who are true Crypto lovers, People who
                        are true Art lovers and more precisely, People who
                        believe that these 2 worlds can merge and should merge..
                      </Accordion.Body>
                    </p>
                  </Accordion.Item>
                </div>
                <div className='faq-content-detail'>
                  <Accordion.Item className='faq-content-detail' eventKey='12'>
                    <h3>
                      <Accordion.Header>
                        <span>
                          I haven't mint any NFT in a long time, so why should I
                          mint this one ?
                        </span>
                      </Accordion.Header>
                    </h3>

                    <p>
                      <Accordion.Body>
                        Because for once, you won’t throw away your money in
                        another creepy project, but you’ll invest it in a strong
                        one, who communicates with clarity and who might be the
                        next big thing…
                      </Accordion.Body>
                    </p>
                  </Accordion.Item>
                </div>
              </Accordion>
            </div>
          </div>

          <div className='faq-cartoon-img-two hidden hidden1'>
             <LazyLoad height={1600}> 
            <img src='images/panda-img5.webp' alt='Digicollect' />
             </LazyLoad> 
          </div> */}

          <div className='footer-content'>
            <div className='footer-icon'>
              {/* <LazyLoad> */}
              <img src='images/digi-logo.webp' alt='Digicollect' style={{ width: '200px' }} />
              {/* </LazyLoad> */}
            </div>
            <ul>
              <li>
                <a href='https://twitter.com/DigicollectLabs' target={'_blank'}>
                  <img src='images/twitter-icon.png' alt='icon' />
                </a>
              </li>
               <li>
                <a href='https://youtube.com/@digicollectlabs2329'  target={'_blank'}>
              <img src='images/youtube-icon.png' alt='icon' />
                </a>
                </li> 
               <li> <a href='https://youtube.com/@digicollectlabs2329'  target={'_blank'}>
              <img src='images/discord-icon.png' alt='icon' />
                </a>
                </li>
                <li>
                  <a href='https://youtube.com/@digicollectlabs2329' target={'_blank'}>
                  <img src='images/medium-icon.png' alt='icon' />
                  </a>
                </li>
              {/* <li>
                <a href='instagram.com'>
                  <img src='images/instagram-icon.webp' alt='icon' />
                </a>
              </li> */}
            </ul>
          </div>
        </div>
      </section>
    </>
  );
};

export default Home;
