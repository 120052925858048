import React, { useEffect, useRef, useState } from 'react';

import { Col, Row } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import { Link } from 'react-router-dom';
import { connectWallet, purchaseDigiCollect, shortAddress} from '../bnb-setup/apis';



import DotBar from './dotbar';


import { useTimer } from 'react-timer-hook';
import { addTokenToMetaMask } from '../bnb-setup/metamask-connection';

function detectMob() {
  if (window.innerWidth <= 500 && window.innerHeight <= 920) return 'mobile';
  if (window.innerWidth <= 1024 && window.innerHeight <= 1244) return 'tablet';
  if (window.innerWidth <= 1440 && window.innerHeight <= 1244) return 'laptop_small';
  else return 'laptop_large';
}

// comment

const isMobile = detectMob();

// Get Guidance From Front End Experts, About this code, improvements
const observer = new IntersectionObserver((entries) => {
  entries.forEach((entry) => {
    if (entry.isIntersecting) {
      entry.target.classList.add('show');
    } else {
      entry.target.classList.remove('show');
    }
  });
});

const EXPIRY_TIMESTAMP = Date.now() + 24 * 60 * 60 * 1000; // Set the expiry timestamp to 24 hours from now

const Timer1 = () => {
  const [expiryTimestamp, setExpiryTimestamp] = useState(() => {
    // Try to get the expiry timestamp from local storage
    const storedTimestamp = window.localStorage.getItem('expiryTimestamp');

    // If the stored timestamp is valid, return it; otherwise, return the default timestamp
    return storedTimestamp ? parseInt(storedTimestamp, 10) : EXPIRY_TIMESTAMP;
  });

  useEffect(() => {
    // Save the current expiry timestamp to local storage whenever it changes
    window.localStorage.setItem('expiryTimestamp', expiryTimestamp.toString());
  }, [expiryTimestamp]);

  const { seconds, minutes, hours, isRunning } = useTimer({
    expiryTimestamp: expiryTimestamp,
    onExpire: () => { 
      // When the timer expires, update the expiry timestamp to 24 hours from now
      setExpiryTimestamp(EXPIRY_TIMESTAMP);
    },
  });

  return (
    <div className='neonText hidden hidden1' style={{ textAlign: 'center', marginTop: '20px' }}>
      {hours} hrs : {minutes} mins : {seconds} sec
    </div>
  );
};

// const Timer2 = () => {
//   const { seconds, minutes, hours, isRunning } = useTimer({
//     expiryTimestamp: Date.parse(timerDate),
//     onExpire: () => { },
//   });

//   return (
//     <div className='neonText' style={{ textAlign: 'center', marginTop: '35px' }}>
//       {hours} hrs : {minutes} mins : {seconds} sec
//     </div>
//   );
// };

const Token = () => {
  // const [qty, setQty] = useState(1);
  const [wallet, setWallet] = useState(null);
  const defReferrer = '0xC9149D1c8305656b2Af0701A7B498c4f90B54dE6';
  const queryParams = new URLSearchParams(window.location.search);
  const referrer = queryParams.get('referrer') || defReferrer;
  const [loader, setLoader] = useState(false);
  const [toastMessage, setToastMessage] = useState(false);
  const [showMessage, setShowMessage] = useState(false);

  const digiPrice = 0.016;
  const [value, setValue] = useState(digiPrice);

  const setToastText = (text) => {
    setToastMessage(text);
    setTimeout(() => {
      setToastMessage(false);
    }, 4000);
  };
  const counterRef = useRef(null);
  const [price, setPrice] = useState("");
  const [priceInfo, setPriceInfo] = useState("");
  const [balance, setBalance] = useState(0);
  const [reward, setReward] = useState(null);
  const [tokenIds, setTokenIds] = useState(null);
  const [deposits, setDeposits] = useState(null);
  const [qty, setQty] = useState(0.01);

  const decrement = () => {
    setQty(qty > 0.01 ? qty - 0.01 : qty);
  };

  const increment = () => {
    setQty(qty < 10 ? qty + 0.01 : qty);
  };

  const debounce = (callback) => {
    setTimeout(callback, 100);
  };

  const render = (hideClassName, visibleClassName) => {
    if (counterRef.current) {
      counterRef.current.classList.add(hideClassName);

      setTimeout(() => {
        counterRef.current.innerText = qty;
        counterRef.current.classList.add(visibleClassName);
      }, 100);

      setTimeout(() => {
        counterRef.current.classList.remove(hideClassName);
        counterRef.current.classList.remove(visibleClassName);
      }, 1100);
    }
  };

  const set = {
    1: () => setQty(2),
    2: () => setQty(3),
    3: () => setQty(1),
  };
  const videos = ['images-mint/pnada pick another panda.mp4', 'images-mint/PAnda world tour.mp4', 'images-mint/Sea panda.mp4'];
  function getRandomIndex() {
    return Math.floor(Math.random() * videos.length);
  }
    const videoIndex = getRandomIndex();
 

  
  useEffect(() => {
    document.querySelectorAll('.hidden').forEach((el) => observer.observe(el));
  }, []);

  useEffect(() => {
    // getPrice(setPrice);
    // wallet && getBalance(setBalance);
    // wallet && setInterval(() => {
    //   getReward(setReward)
    //   getDeposits(setDeposits)
    //   getTokenIds(setTokenIds)
    // },5000)
  },[wallet])

    
  const center = { marginLeft: 'auto', marginRight: 'auto' };
 
 

  return isMobile === 'mobile' ? (
    <div style={{ position: 'absolute', backgroundColor: 'rgb(0, 0, 0)', height: '100vh', width: '100vw' }}>
     <Link to="/"> <img className='hidden hidden1' src='images/digi-logo.webp' alt='Digicollect' style={{ height: '55px', marginTop: '20px', ...center }} /></Link>
     
     <Link to="/"> <img src='images/digitoken.png' alt='digi-collect' style={{ height: '95px', ...center }} /></Link>

      {/* <Timer1 className='hidden hidden1' /> */}
      <div style={{ display: 'flex', flexDirection: 'col', height: '70x', marginTop: '20px', ...center , width:'fit-content', height:'50px'}}>
        <DotBar 
          digiPrice={digiPrice}
          value = {value}
          setValue = {setValue}/>
  </div>

      <div className='hidden hidden1' style={{ display: 'flex', flexDirection: 'col', height: '42px', marginTop: '125px', justifyContent:'center' }}>
      <div className="ctrl" style={{justifyContent:'center',background:'none' }}>
          <Button variant="outline-warning" className='custom-btn-size1' onClick={() => purchaseDigiCollect(setLoader, value, setToastText, setWallet, setShowMessage, referrer)}
        alt='Digicollect'
        >Buy $DIGI</Button>{' '}
  
</div>
         {/* <img
          src='images-mint/BTN MINT.png'
          onClick={() => purchaseMorphsWhitelist(setLoader, qty, setToastText, setWallet, setShowMessage)}
          // onClick={() => purchaseMorphs(setLoader, qty, setToastText, setWallet, setShowMessage)}
          alt='Digicollect'  
        />  */}
         </div>
      
    
     
   
      <Button variant="outline-warning" size="lg" onClick={() => {
          connectWallet(setWallet);
        }}
        alt='Digicollect'
        className='hidden hidden1 font'
        style={{ height: '45px', marginTop: '30px', marginLeft:'30%',}}>{ shortAddress(wallet) ? 'CONNECTED' : '.CONNECT.'}</Button>{' '}
<div 
                style={{textAlign: 'center', marginTop:'10px'}}>
                
            
                <Button variant="outline-warning" size="lg" onClick={() => addTokenToMetaMask()} alt='Digicollect'
                  className='font' style={{ marginTop: '30px', textAlign: 'center' }} >
                  Add $DIGI to 🦊 
                </Button>
              </div>
      

      
    </div>
  ) : (
    <div style={{ position: 'absolute', backgroundColor: 'rgb(0, 0, 0)', width: '100vw' }}>
      <div style={{ paddingTop: '60px', paddingLeft: '60px', paddingRight: '40px' }}>
        <Row className='hidden hidden1' style={{display:'flex', justifyContent:'space-between'}}>
        <Col lg={2} md={3}>
          <Link to="/"> <img src='images/digi-logo.webp' alt='digi-collect' style={{ height: '75px', ...center }} /></Link>
          
           
          </Col>
          <Col lg={2} md={3} style={{width:'fit-content'}} >
          <Link to="/"> <img src='images/digitoken.png' alt='digi-collect' style={{ height: '95px', ...center }} /></Link>
           <div style={{ display: 'flex', flexDirection: 'col', height: '70x', marginTop: '10px', ...center,justifyContent:'center'}}>
         
      <DotBar
        digiPrice={digiPrice}
        value = {value}
        setValue = {setValue}
        />
    
      </div>
      <div>
          <div className="ctrl" style={{justifyContent:'center',background:'none' }}>
          <Button variant="outline-warning" className='custom-btn-size1' onClick={() => purchaseDigiCollect(setLoader, value, setToastText, setWallet, setShowMessage, referrer)}
        alt='Digicollect'
        >Buy $DIGI</Button>{' '}
  
</div>
</div>


          </Col>
          <Col lg={2} md={2} style={{display:'flex',flexDirection:'column'}}>
          
          <Button variant="outline-warning" size="lg" onClick={() => {
          connectWallet(setWallet);
        }}
        alt='Digicollect'
        className='font'
                style={{ marginTop: '15px'}}>{ shortAddress(wallet) ? 'CONNECTED' : '.CONNECT.'}</Button>{' '}
           <div>
              <div 
                style={{textAlign: 'center', marginTop:'10px'}}>
                
                
                { wallet && reward > 0 && <Button variant="outline-warning" size="lg" onClick={() => purchaseDigiCollect(setLoader, value, setToastText, setWallet, setShowMessage, referrer)} alt='Digicollect'
                  className='font' style={{ marginTop: '30px', textAlign: 'center' }} >
                  Collect {reward.toFixed(4)} DIGI
                </Button>}
                <Button variant="outline-warning" size="lg" onClick={() => addTokenToMetaMask()} alt='Digicollect'
                  className='font' style={{ marginTop: '30px', textAlign: 'center' }} >
                  Add $DIGI to 🦊 
                </Button>
              </div>
            </div>
            </Col>
        {/* <Row>
        <Timer1 className='hidden hidden1' />
        </Row> */}
        </Row>
       
          <Row>
          <center>
         <video autoPlay loop muted  inline='true' width='90%' style={{ marginTop: '35px' }}>
         <source src={videos[videoIndex]} type="video/mp4" />
         </video>
       </center>
        </Row>
      </div>
    
    </div>
  );
};

export default Token;