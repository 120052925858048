import React, { useState } from "react";


const DotBar = ({value, setValue, digiPrice}) => {

  const handleChange = (e) => {
    setValue(parseFloat(e.target.value));
  };

  const scale = (num, in_min, in_max, out_min, out_max) => {
    return ((num - in_min) * (out_max - out_min)) / (in_max - in_min) + out_min;
  };

  const DIGI_TOKEN_PER_BNB = 100000;
  const digiTokenValue = (value * DIGI_TOKEN_PER_BNB).toFixed(0);
  const digiTokenText = `${value} BNB = ${digiTokenValue} DIGI Token`;

  const leftDotPosition = 0;
  const rightDotPosition = 300;

  const left =
    scale(value, 0.01, 10, leftDotPosition, rightDotPosition) + scale(value, 0.01, 10, 10, -10);

  const right = rightDotPosition;

  const handleLabelClick = () => {
    const userInput = prompt("Enter the value:");
    if (userInput !== null) {
      setValue(parseFloat(userInput));
    }
  };

  return (
    <div className='range-container'>
      <div className='circular-slider'>
        <div className='slider-start'></div>
        <input
          type='range'
          name='range'
          id='range'
          min={'' + digiPrice}
          max='10'
          step={'' + digiPrice}
          value={value}
          onChange={handleChange}
        />
        <div className='slider-end'></div>
      </div>
      <label
        htmlFor='range'
        style={{ left: `${left}px` }}
        onClick={handleLabelClick}
      >
        {value} BNB
      </label>
      <h2 style={{ fontSize: '25px', textAlign: 'center' }}>
        {((value / digiPrice) * 5).toFixed(0)} USDT =
        {((value / digiPrice) * 1000).toFixed(0)} Digi Token
      </h2>
      <h2 style={{ fontSize: '25px', textAlign: 'center' }}>{digiTokenText}</h2>
    </div>
  );
};

export default DotBar;
